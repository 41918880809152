import React from 'react';
import Header from './components/Header';
import Rooms from './components/Rooms';
import styled from 'styled-components';
import Welcome from './components/Welcome';
import Services from './components/Services';
import Places from './components/Places';
import Remoteness from './components/Remoteness';
import { Helmet } from "react-helmet";
import { SEO } from "./components/assets";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const App = () => {
    return (
        <Container>
            <Helmet>
                <title>Добре дошли | къща за гости "Магделина"</title>
                <meta name="description" content="Където душата и съзнанието са в мир и баланс, сред спокойствие, богата природа и уют | къща за гости 'Магделина'" />
                <meta name="keywords" content="почивка в България, почивка на планина, къща за гости магделина, почивка, лято, зима, къща за гости добринище, къща за гости около софия, къща за гости за нова година, къща за гости в планината, магделина, банско, туризъм, курорт, зимни курорти, Пирин, Рила, Родопи, Банско, ски, минерални басейни, добринище, хотели в добринище, почивка в добринище, ски в добринище, лифт добринище" />

                <meta property="og:title" content="Добре дошли | къща за гости 'Магделина'" />
                <meta property="og:description" content="Където душата и съзнанието са в мир и баланс, сред спокойствие, богата природа и уют | къща за гости 'Магделина'" />
                <meta property="og:image" content={SEO.home} />
                <meta property="og:url" content="https://magdelina.bg" />
                <meta property="og:site_name" content="Къща за гости 'Магделина'" />
            </Helmet>
            <Header />
            <Welcome />
            <Rooms />
            <Services />
            <Places />
            <Remoteness />
            {/* <Social /> */}
        </Container>

    )
}

export default App;