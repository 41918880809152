import React from 'react';
import styled from 'styled-components';
import RemotenessInfo from './RemotenessInfo';
import DescribedInfo from './DescribedInfo';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    ${props => props.theme.media.desktop} {
        flex-direction: row;
    }
`;
const Remoteness = () => {
    return (
        <Wrapper>
            <RemotenessInfo />
            <DescribedInfo />
        </Wrapper>
    )
}

export default Remoteness;