import React from 'react';
import styled from 'styled-components';
import Line from '../Line';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlaneDeparture, faPassport, faCalendarAlt, faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    background: #f1f2f2;
    order: 0;
    ${props => props.theme.media.tablet} {
        order: 1;
    }
`;

const Title = styled.h2`
    font-size: 3.125rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
`;

const Description = styled.p`
    font-size: 1.375rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
`;

const Icons = styled.div`
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 42px;
    flex-wrap: wrap;
    ${props => props.theme.media.desktop} {
        margin-bottom: 20px;
    }
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid rgba(15,36,83,0.4);
    width: 50px;
    height: 50px;
    padding: 5px;
    margin: 7px;
    ${props => props.theme.media.tablet} {
        &:first-child {
            margin-left: 0;
        }
    }
`;

const WelcomeInfo = () => {
    return (
        <Wrapper>
            <Title>Сред спокойствие, богата природен и уют</Title>
            <Line />
            <Description>
                Добринище е спокойно и китно градче, разположено на 850 м. надморска височина, в полите на Пирин, радващ се на интереса от туристи, заради чудесните условия за почивка сред природата и интересни забележителности.
            </Description>
            <Icons>
            <IconWrapper><FontAwesomeIcon icon={faPlaneDeparture} size="2x" /></IconWrapper>
                <IconWrapper><FontAwesomeIcon icon={faPassport} size="2x" /></IconWrapper>
                <IconWrapper><FontAwesomeIcon icon={faCalendarAlt} size="2x" /></IconWrapper>
                <IconWrapper><FontAwesomeIcon icon={faMapMarkedAlt} size="2x" /></IconWrapper>
            </Icons>
        </Wrapper>
    )
}

export default WelcomeInfo;