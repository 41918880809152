import React from 'react';
import styled from 'styled-components';
import WelcomeInfo from './WelcomeInfo';
import DescribedInfo from './DescribedInfo';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${props => props.theme.media.desktop} {
        flex-direction: row;
    }
`;

const Welcome = () => {
    return (
        <Wrapper>
            <DescribedInfo />
            <WelcomeInfo />
        </Wrapper>
    )
}

export default Welcome;