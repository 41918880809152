const media: any = {
    ulg: '@media only screen and (min-width: 1620px)', 
    lg: '@media only screen and (min-width: 1200px)',
    desktop: '@media only screen and (min-width: 960px)',
    tablet: '@media only screen and (min-width: 600px)',
    mobile: '@media only screen and (min-width: 0px)'
}

export default {
    media
}