import React from 'react';
import styled from 'styled-components';
import RemotePlace from './RemotePlace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSwimmer, faUtensils, faShoppingBasket, faHospital, faBusAlt, faSkiing, faBuilding, faPlaneDeparture } from '@fortawesome/free-solid-svg-icons'

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    flex: 1;
    order: 1;
    ${props => props.theme.media.desktop} {
        height: 500px;
        order: 0;
    }
`;

const RemotenessInfo = () => {
    const services = [
        {
            id: 1,
            name: "Плаж",
            distance: "500м.",
            icon: <FontAwesomeIcon icon={faSwimmer} size="2x" />,
        }, {
            id: 2,
            name: "Ресторанти",
            distance: "100м.",
            icon: <FontAwesomeIcon icon={faUtensils} size="2x" />,
        }, {
            id: 3,
            name: "Магазин",
            distance: "100м.",
            icon: <FontAwesomeIcon icon={faShoppingBasket} size="2x" />,
        }, {
            id: 4,
            name: "Автобусна спирка",
            distance: "500м.",
            icon: <FontAwesomeIcon icon={faBusAlt} size="2x" />,
        }, {
            id: 5,
            name: "СКИ писта",
            distance: "10км.",
            icon: <FontAwesomeIcon icon={faSkiing} size="2x" />,
        }, {
            id: 6,
            name: "Център",
            distance: "50м.",
            icon: <FontAwesomeIcon icon={faBuilding} size="2x" />,
        }, {
            id: 7,
            name: "Летище",
            distance: "160км.",
            icon: <FontAwesomeIcon icon={faPlaneDeparture} size="2x" />,
        }, {
            id: 8,
            name: "Болница",
            distance: "50м.",
            icon: <FontAwesomeIcon icon={faHospital} size="2x" />,
        }
    ];
    return (
        <Wrapper>
            {services.map(service => (
                <RemotePlace key={service.id} {...service} />
            ))}
        </Wrapper>
    )
}

export default RemotenessInfo;