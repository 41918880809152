import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
`;

const Logo = styled.a.attrs(props => ({
    href: props.href || "/"
}))`
    display: block;
    color: #2b2924;
    text-decoration: none;
`;

const LeftSide = styled.div`
    display: flex;
    justify-content: space-between;
    flex: 1;
    align-items: center;
`;

const Hamburger = styled.div`
    display: flex;
    align-items: center;

    ${props => props.theme.media.tablet} {
        display: none;
    }
`;

const Nav = styled.nav`
    display: none;
    ${props => props.theme.media.tablet} {
        display: inline-block;
    }
`;
const MenuText = styled.span`
    margin-right: 5px;
`;

const Anchor = styled(Link)`
    display: block;
    padding: 14px ${props => props.padding ? '15px' : '0'};
    color: #000;
    text-decoration: none;
    border-radius: 2px;
    margin: 0 auto;
    &:hover {
        background: #f1f2f2;
    }

    ${props => props.theme.media.tablet} {
        display: inline-block;
        width: auto;
    }

`;

const MobileNavContainer = styled.div`
    
`;

const NavContainer = styled.div`
    display: flex;
    align-items: center;
    height: 48px;
`;

const MobileNavContent = styled.div`
    position: absolute;
    top: 48px;
    left: 0;
    width: 100%;
    background: #fff;
    border-top: 1px solid #f1f2f2;
    z-index: 111111;
`;

const MobileAnchorsList = styled.ul`
    margin: 0;
    padding: 0;
`;
const MobileAnchorItem = styled.li`
    list-style: none;
`;
const MobileAnchor = styled.a.attrs(props => ({
    href: props.href || '#'
}))`
    display: block;
    color: #000;
    text-decoration: none;
    padding: 10px 40px;
`;

const AnchorText = styled.span`
    width: 80%;
    margin: 0 auto;
    display: block;
`;

const mobileLinks = [
    {
        id: 1,
        name: 'Начало',
        href: '/'
    },
    {
        id: 2,
        name: 'Настаняване',
        href: '/stai'
    },
    {
        id: 3,
        name: 'Контакти',
        href: '/kontakti'
    }
];

const DesktopNavigation = () => {
    return (
        <Nav>
            <Anchor to='/' padding={1}>Начало</Anchor>
            <Anchor to='/stai' padding={1}>Настаняване</Anchor>
            <Anchor to='/kontakti' padding={1}>Контакти</Anchor>
            {/* <Anchor to='/'>Посетете</Anchor> */}
            {/* <a href='#services'>Услуги</a> */}
            {/* <Anchor to='/'>Забележителности</Anchor>
            <Anchor to='/'>В близост</Anchor>
            <Anchor to='/'>В мрежата</Anchor> */}
        </Nav>
    );
}


const MobileNavAnchors = ({onClick}: any) => {
    return (
        <MobileAnchorsList>
            {mobileLinks.map(link => (
                <MobileAnchorItem key={link.id}>
                    <Anchor to={link.href} onClick={onClick}><AnchorText>{link.name}</AnchorText></Anchor>
                </MobileAnchorItem>
            ))}
        </MobileAnchorsList>
    )
}
const MobileNavigation = () => {
    const [isOpen, setIsMobileMenuOpen] = useState(false);
    return (
        <MobileNavContainer>
            <Hamburger onClick={() => setIsMobileMenuOpen(!isOpen)}>
                <MenuText>{isOpen ? 'Затваряне' : 'Меню'}</MenuText>
                <FontAwesomeIcon icon={faBars} />
            </Hamburger>
            {isOpen && <MobileNavContent><MobileNavAnchors onClick={() => setIsMobileMenuOpen(!isOpen)} /></MobileNavContent>}
        </MobileNavContainer>
    )
}


const isMobile = window.matchMedia('(max-width: 599px)').matches;
const Navigation = () => {
    return (
        <Wrapper>
            <LeftSide>
                <Logo><i>Kъща за гости<br />"Магделина"</i></Logo>
                <NavContainer>

                    {isMobile ? <MobileNavigation /> : <DesktopNavigation />}
                </NavContainer>
            </LeftSide>
        </Wrapper>
    )
}

export default Navigation;