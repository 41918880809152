export const filePath = (requirePath: string): string => requirePath;

export const HomeVideo = filePath(require('assets/home-video.mp4'));

export const doubleRoom = {
    imagewebp: filePath(require('assets/IMG_1463.webp')),
    imagejpg: filePath(require('assets/IMG_1463.jpg')),
    gallery: [
        {
            src: filePath(require('assets/IMG_1463.webp')),
            width: 1,
            height: 1
        }, {
            src: filePath(require('assets/IMG_1465.webp')),
            width: 1,
            height: 1
        }, {
            src: filePath(require('assets/IMG_1470.webp')),
            width: 1,
            height: 1
        }, {
            src: filePath(require('assets/IMG_1473.webp')),
            width: 1,
            height: 1
        }, {
            src: filePath(require('assets/IMG_1481.webp')),
            width: 1,
            height: 1
        },
        {
            src: filePath(require('assets/IMG_1485.webp')),
            width: 1,
            height: 1
        }
    ]
}

export const tripleRoom = {
    imagewebp: filePath(require('assets/IMG_1490.webp')),
    imagejpg: filePath(require('assets/IMG_1490.jpg')),
    gallery: [
        {
            src: filePath(require('assets/IMG_1490.webp')),
            width: 1,
            height: 1
        }, {
            src: filePath(require('assets/IMG_1493.webp')),
            width: 1,
            height: 1
        }, {
            src: filePath(require('assets/IMG_1494.webp')),
            width: 1,
            height: 1
        }, {
            src: filePath(require('assets/IMG_1495.webp')),
            width: 1,
            height: 1
        }
    ]
}

export const apartment = {
    imagewebp: filePath(require('assets/IMG_1507.webp')),
    imagejpg: filePath(require('assets/IMG_1507.jpg')),
    gallery: [
        {
            src: filePath(require('assets/IMG_1507.webp')),
            width: 1,
            height: 1
        }, {
            src: filePath(require('assets/IMG_1503.webp')),
            width: 1,
            height: 1
        }, {
            src: filePath(require('assets/IMG_1504.webp')),
            width: 1,
            height: 1
        }, {
            src: filePath(require('assets/IMG_1510.webp')),
            width: 1,
            height: 1
        }
    ]
}


// places
export const mineralenPlaj = {
    imagewebp: filePath(require('assets/mineralen-plaj.webp')),
    imagejpg: filePath(require('assets/mineralen-plaj.jpg'))
}

export const bezimenniyatGrad = {
    imagewebp: filePath(require('assets/bezimenniyat-grad.webp')),
    imagejpg: filePath(require('assets/bezimenniyat-grad.jpeg')),
}

export const atrakcionenPark = {
    imagewebp: filePath(require('assets/atrakcionen-park.webp')),
    imagejpg: filePath(require('assets/atrakcionen-park.jpeg'))
}

export const ribarnika = {
    imagewebp: filePath(require('assets/ribarnika.webp')),
    imagejpg: filePath(require('assets/ribarnika.jpeg'))
}

export const obidimskiManastir = {
    imagewebp: filePath(require('assets/obidimski-manastir.webp')),
    imagejpg: filePath(require('assets/obidimski-manastir.jpg'))
}

export const konnaBaza = {
    imagewebp: filePath(require('assets/konna-baza.webp')),
    imagejpg: filePath(require('assets/konna-baza.jpg'))
}

export const kashtaNikolValpcarov = {
    imagewebp: filePath(require('assets/kashta-n-vapcarov.webp')),
    imagejpg: filePath(require('assets/kashta-n-vapcarov.jpg'))
}

export const kashtaNeofitRilski = {
    imagewebp: filePath(require('assets/kashta-n-rilski.webp')),
    imagejpg: filePath(require('assets/kashta-n-rilski.jpg'))
}

export const SEO = {
    home: filePath(require('assets/home_og_img.jpg')),
}