import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import { BrowserRouter } from "react-router-dom";

import { ThemeProvider } from 'styled-components';
import theme from './app/theme/theme';
import GlobalStyle from './app/components/GlobalStyles';

ReactDOM.render(
  // <BrowserRouter>
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <App />
  </ThemeProvider>,
  // </BrowserRouter>
  document.getElementById('root')
);