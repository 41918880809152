import React from 'react';
import styled from 'styled-components';
import Line from '../Line';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSwimmer, faSun, faSkiing } from '@fortawesome/free-solid-svg-icons'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    order: 1;
    ${props => props.theme.media.tablet} {
        order: 0;
    }
`;

const Title = styled.h2`
    font-size: 3.125rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
`;

const Description = styled.p`
    font-size: 1.375rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
`;

const Icons = styled.div`
    display: flex;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 42px;
    flex-wrap: wrap;
    ${props => props.theme.media.desktop} {
        margin-bottom: 20px;
    }
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid rgba(15,36,83,0.4);
    width: 50px;
    height: 50px;
    padding: 5px;
    margin: 7px;
    ${props => props.theme.media.tablet} {
        &:first-child {
            margin-left: 0;
        }
    }
`;

const RightSide = () => {
    return (
        <Wrapper>
            <Title>Където душата и съзнанието са в мир и баланс</Title>
            <Line />
            <Description>
                Къща Магделина се намира в подножието на Пирин планина, в едно от най-китните и предпочитани курортни селища Добринище.
                Свеж, прохладен въздух, уникално красива природа, тишина и спокойствие са само част от предимствата на къщата.
            </Description>
            <Icons>
                <IconWrapper><FontAwesomeIcon icon={faSun} size="2x" /></IconWrapper>
                <IconWrapper><FontAwesomeIcon icon={faSwimmer} size="2x" /></IconWrapper>
                <IconWrapper><FontAwesomeIcon icon={faSkiing} size="2x" /></IconWrapper>
            </Icons>
        </Wrapper>
    )
}

export default RightSide;