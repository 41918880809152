import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    background: #f1f2f2;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    width: 80%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;

    ${props => props.theme.media.tablet} {
        flex-direction: row;
    }
`;

const SocialColumn = styled.div`
    display: flex;
    padding: 10px 0;
    width: 80%;
    margin: 0 auto;
`;

const ContactColumn = styled.div`

`;

const Footer = () => {
    return (
        <Wrapper>
            <Content>
                <SocialColumn>&copy;2010-2021 къща за гости "Магделина"</SocialColumn>
                {/* | Общи условия за ползване на сайта */}
                <ContactColumn></ContactColumn>
            </Content>
        </Wrapper>
    )
}

export default Footer;