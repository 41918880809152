import React from 'react';
import styled from 'styled-components';
import { useBackgroundWebp } from '../utils/webp';

const CardContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(15,36,83,0.4);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`;

const Content = styled.p`
    visibility: hidden;
    opacity: 0;
    width: 80%;
    transition: visibility 0s, opacity 0.5s linear;
`;

const Image = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: url('${props => useBackgroundWebp(props.imagewebp, props.imagejpg)}');
    background-repeat: no-repeat;
    background-size: cover;
    transition: transform .4s;
    ${props => props.theme.media.desktop} {
        &:hover {
            transform: scale(1.2);
        }
    }
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 0 0 300px;
    height: 300px;
    overflow: hidden;
    position: relative;

    &:hover ${Content} {
        visibility: visible;
        opacity: 1;
    }

    &:hover ${Image} {
        transform: scale(1.2);
    }

    ${props => props.theme.media.tablet} {
        height: 400px;
        flex: 0 0 400px;
    }

    ${props => props.theme.media.desktop} {
        flex: 0 0 25%;
    }
`;

const Header = styled.h1`
    margin-left: 15px;
    margin-bottom: 15px;
    color: #fff;
    font-size: 1.7em;
    opacity: .9;
    text-shadow: 2px 2px 2px #000;
    font-weight: 500;
`;

const Anchor = styled.a.attrs(props => ({
    href: props.href || "#",
    target: "_blank",
    rel: "noopener noreferrer"
}))`
    width: 100%:
    height: 100%;
`;

const Room = ({
    images,
    name,
    link
}) => {
    return (
        <Card>
            <Anchor href={link}>    
                <Image imagewebp={images.imagewebp} imagejpg={images.imagejpg}/>
                <CardContent>
                    <Header>
                        <i>{name}</i>
                    </Header>
                </CardContent>
            </Anchor>
        </Card>
    )
}

export default Room;