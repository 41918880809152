import React from 'react';
import styled from 'styled-components';
import { useBackgroundWebp } from '../utils/webp';
import { Link } from 'react-router-dom';

const CardContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(15,36,83,0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const Content = styled.p`
    opacity: 0;
    width: 80%;
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
    height: 300px;
    background: url('${props => useBackgroundWebp(props.imagewebp, props.imagejpg)}');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &:hover ${Content} {
        visibility: visible;
        opacity: 1;
    }
    ${props => props.theme.media.tablet} {
        height: 400px;
    }

    ${props => props.theme.media.desktop} {
        flex: 0 0 33.33%;
        height: 500px;
    }
`;

const Header = styled.h1`
    margin-bottom: 7px;
    color: #fff;
    font-size: 2em;
`;

const Actions = styled.div``;

const ReadButton = styled.div`

`;

const Image = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: url('${props => useBackgroundWebp(props.imagewebp, props.imagejpg)}');
    background-repeat: no-repeat;
    background-size: cover;
    transition: transform .4s;
    background-position: 0 -160px;

    ${props => props.theme.media.tablet} {
        background-position: 0 -300px;
    }

    ${props => props.theme.media.desktop} {
        background-position: 0;
    }

    ${props => props.theme.media.ulg} {
        background-position: 0 -160px;
    }
`;

const Action = styled(Link)`
    display: block;
    background: transparent;
    border: 2px solid #bdc9e0;
    color: #fff;
    text-decoration: none;
    padding: 7px 10px;
    border-radius: 2px;
    transition: background, color 0.2s ease;
    &:hover {
        background: #bdc9e0;
        color: #000;
    }
`;

const Room = ({
    id,
    images,
    name,
    description,
    type
}) => {

    return (
        <Card>
            <Image imagewebp={images.imagewebp} imagejpg={images.imagejpg} />
            <CardContent>
                <Header>
                    <i>{name}</i>
                </Header>
                <Actions>
                    <ReadButton>
                        <Action to={`/stai/${id}/${type}`} >
                            Прочетете още
                        </Action>
                    </ReadButton>
                </Actions>
            </CardContent>
        </Card>
    )
}

export default Room;