import React from 'react';
import styled from 'styled-components';
import Room from './Room';

import { doubleRoom, tripleRoom, apartment } from '../assets';

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;

const Rooms = () => {
    const rooms = [
        {
            id: 1,
            name: "Двойна стая",
            description: "Стая за двама, с възможност за настаняване на дете на допълнително легло",
            images: doubleRoom,
            type: "dvoina-staya"
        },
        {
            id: 2,
            name: "Тройна стая",
            description: "Стая за двама, с възможност за настаняване на дете на допълнително легло",
            images: tripleRoom,
            type: "troina-staya"
        },
        {
            id: 3,
            name: "Апартамент",
            description: "Стая за двама, с възможност за настаняване на дете на допълнително легло",
            images: apartment,
            type: "apartament"
        },
    ]

    return (
        <Wrapper>
            {rooms.map(room => (
                <Room key={room.id} {...room} />
            ))}
        </Wrapper>
    )
}

export default Rooms;