import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

const Wrapper = styled.div`
    text-align: center;
`;

const Anchor = styled.a.attrs(props => ({
    href: props.href || "tel: +359 88 971 9973"
}))`
    display: block;
    background: #cfe0f0;
    padding: 10px 7px;
    color: #2b2924;
`;

const Phone = () => {
    return (
        <Wrapper>
            <Anchor>
                <FontAwesomeIcon icon={faPhone} size="lg" />
            </Anchor>
        </Wrapper>
    )
}

export default Phone;