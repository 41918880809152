import React from 'react';
import styled from 'styled-components';
import InfoCard from './components/Contact/InfoCard';
import { faInfo, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from "react-helmet";
import Carousel from './components/Header/Carousel';
const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Header = styled.div`
    width: 100%;
    height: 210px;
    background: #ccc;
    overflow: hidden;
    position: relative;
    ${props => props.theme.media.tablet} {
        height: 400px;
    }
`;

const Map = styled.iframe.attrs({
    src: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2973.3102988402525!2d23.559803515916197!3d41.82161597692518!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14abaf5ca47b29e7%3A0xf213118005f4a53a!2z0YPQuy4g4oCe0JDQtNCw0Lwg0KLRgNGK0L3QutCw4oCcIDE5LCAyNzc3IERvYnJpbmlzaHRl!5e0!3m2!1sen!2sbg!4v1627828169808!5m2!1sen!2sbg",
    allowFullScreen: true,
    loading: "lazy"
})`
    width: 100%;
    height: 400px;
    border: none;
`;

const Content = styled.div`
    width: 95%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 35px 0;

    ${props => props.theme.media.tablet} {
        width: 80%;
    }

    ${props => props.theme.media.desktop} {
        width: 70%;
    }

    ${props => props.theme.media.lg} {
        width: 50%;
    }
`;
const Sections = styled.div`

`;

const SectionTitle = styled.h1`

`;

const SectionText = styled.p`

`;

const ContactsInfo = styled.div`
    display: flex;
    flex-direction: column;

    ${props => props.theme.media.tablet} {
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }
`;

const Contact = () => {
    const info = [
        {
            name: "Адрес",
            icon: faMapMarkerAlt,
            description: "гр. Добринище,<br/> ул. \"Адам Трънка 19\""
        },
        {
            name: "За резервации и информация",
            icon: faInfo,
            description: "email: info@magdelina.bg<br />tel: +359 889 71 99 73<br />tel: +359 886 30 10 38"
        }
    ]
    return (
        <Container>
            <Helmet>
                <title>Свържете се с нас! | къща за гости "Магделина"</title>
            </Helmet>
            <Header>
                <Carousel text={false} />
            </Header>
            <Content>
                <SectionTitle>
                    Къща за гости Магделина
                </SectionTitle>
                <SectionText>
                    Къща Магделина се намира в едно от известните и предпочитани курортни селища Добринище, в подножието на Пирин планина. Разполага с леглова база от 37 места. Разполага със 7 двойни стаи, 2 тройни стаи, 4 апартамента (единият с 3 стаи, едната от които е кухня и 2 санитарни възела). Капацитет на къщата - 36 легла, с възможност за поставяне на допълнително легло. Стаите са луксозни, всяка една е идентична със своя цвят. Разполагат със самостоятелен санитарен възел, телевизор, кабелна телевизия, Wi-Fi интернет, локално парно отопление, гардероб, топла вода през цялото време и тераси с прекрасен изглед към Пирин, Рила и Родопите. Има зала за изхранване и развлечения с 40 места, както и лятна градина.
                    <br />
                    Къща Магделина посреща гости целогодишно. Пленява с топло и гостоприемно посрещане, първокласно обслужване, екологична храна и изискани напитки. В околността има цели 17 топли минерални извори, а водата в тях има доказани лечебни свойства за организма.
                </SectionText>

                <SectionTitle>
                    Зима в къща за гости Магделина
                </SectionTitle>
                <SectionText>
                    През зимния сезон в района, ски може да карате на ски пистите над Добринище или в зимен курорт Банско, който се намира само на 6 км от града. Добринище успява да се наложи и като един от предпочитаните зимни курорти у нас, където може да се покарате ски или пък, ако сте почитатели на по-високоадреналиновите преживявания – сноуборд. Пистата, която курорта предлага, е с дължина 5 км и води началото си от известната хижа Безбог. Тя предлага трасета подходящи както за опитни скиори, така и за новобранци в зимните спортове. F
                </SectionText>

                <SectionTitle>
                    Лято в къща за гости Магделина
                </SectionTitle>
                <SectionText>
                    През лятото може да плувате, почивате или да се развличате в луксозния минерален плажен комплекс, който разполага с два басейна, воден бар, ресторант с лятна тераса, детска площадка и други.
                </SectionText>

                <SectionTitle>
                    Природа и уютни мигове
                </SectionTitle>
                <SectionText>
                    Къщата е разположена в непосредствена близост до централната част, недалеч от минералния плаж и балнеолечебния център. Чудесно място за релакс и пълноценен отдих. Свеж, прохладен въздух, уникално красива природа, тишина и спокойствие са само част от предимствата на къщата. Къща Магделина осигурява комфорт и уютни мигове, спокойствие сред красивата природа, едно красиво и предпочитано място за почивка в България. В допълнение къщата разполага с тераси с прекрасен изглед към Пирин, Рила и Родопите.
                </SectionText>

                <ContactsInfo>
                    {info.map((i, index) => (
                        <InfoCard key={index} {...i} />
                    ))}
                </ContactsInfo>
            </Content>
            {/* <>ВРЕМЕТО ТУК</> */}
            <Map />
        </Container>
    )
}

export default Contact;