import React from 'react';
import styled from 'styled-components';
import Rooms from './components/Rooms';
import Services from './components/Services';
import Places from './components/Places';
import { Helmet } from "react-helmet";
import { SEO } from "./components/assets";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const RoomsPage = () => {
    return (
        <Container>
            <Helmet>
                <title>Свържете се с нас! | къща за гости "Магделина"</title>
                <meta name="description" content="| къща за гости 'Магделина'" />
                <meta name="keywords" content="почивка в България, почивка на планина, къща за гости магделина, почивка, лято, зима, къща за гости добринище, къща за гости около софия, къща за гости за нова година, къща за гости в планината, магделина, банско, туризъм, курорт, зимни курорти, Пирин, Рила, Родопи, Банско, ски, минерални басейни, добринище, хотели в добринище, почивка в добринище, ски в добринище, лифт добринище" />

                <meta property="og:title" content="7 двойни стаи, 2 тройни стаи, 4 апартамента | къща за гости 'Магделина'" />
                <meta property="og:description" content="| къща за гости 'Магделина'" />
                <meta property="og:image" content={""} />
                <meta property="og:url" content="https://magdelina.bg/#/stai" />
                <meta property="og:site_name" content="7 двойни стаи, 2 тройни стаи, 4 апартамента | къща за гости 'Магделина'" />
            </Helmet>
            <Rooms />
            <Services />
            <Places />
        </Container>
    )
}

export default RoomsPage;