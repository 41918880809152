import { doubleRoom, tripleRoom, apartment } from '../app/components/assets';
import { faBed, faToilet, faTv, faFireAlt, faShower, faPersonBooth, faSeedling, faTshirt } from '@fortawesome/free-solid-svg-icons'

const mainServices = [
    {
        name: "TV",
        icon: faTv
    },
    {
        name: "Самостоятелен санитарен възел",
        icon: faToilet
    },
    {
        name: "Парно отопление",
        icon: faFireAlt
    },
    {
        name: "Гардероб",
        icon: faTshirt
    },
    {
        name: "Топла вода",
        icon: faShower
    },
    {
        name: "Тераса с изглед към Пирин, Рила и Родопите",
        icon: faPersonBooth
    }
];

const doubleRoomServices = [{
    name: "Двойна спалня",
    icon: faBed
}].concat(mainServices);

const tripleRoomServices = [{
    name: "Двойна спалня и единично легло",
    icon: faBed
}].concat(mainServices);

const apartmentServices = [{
    name: "Двойна спалня и единично легло",
    icon: faBed
}, {
    name: "Хладилник",
    icon: faSeedling
}].concat(mainServices);

export const Room = [
    {
        id: 1,
        name: "Двойна стая",
        description: "Стая за двама, с възможност за настаняване на дете на допълнително легло.",
        images: doubleRoom,
        type: "dvoina-staya",
        prices: {
            summer: 50,
            winter: 50
        },
        services: doubleRoomServices,
        details: [
            {
                name: "Легла",
                content: "Спалня за двама"
            }, {
                name: "Закуска",
                content: "Не е включена"
            }, {
                name: "Интернет",
                content: "Включен в пакета, високоскоростен"
            }, {
                name: "Тераса",
                content: "Да"
            }
        ],
        keywords: "стая за двама, двойна стая",
        metaDescription: "Стандартна двойна стая в уют и природен баланс. Насладете се на комфорт, природа и забележителности, заобиколени от планинския триъгълник на Пирин, Рила и Родопи.",
    },
    {
        id: 2,
        name: "Тройна стая",
        description: "Стая за двама, с възможност за настаняване на дете на допълнително легло",
        images: tripleRoom,
        type: "troina-staya",
        prices: {
            summer: 60,
            winter: 60
        },
        services: tripleRoomServices,
        details: [
            {
                name: "Легла",
                content: "Спалня за двама"
            }, {
                name: "Закуска",
                content: "Не е включена"
            }, {
                name: "Интернет",
                content: "Включен в пакета, високоскоростен"
            }, {
                name: "Тераса",
                content: "Да"
            }
        ],
        keywords: "стая за трима, тройна стая",
        metaDescription: "Стая за трима в уют и природен баланс. Насладете се на комфорт, природа и забележителности, заобиколени от планинския триъгълник на Пирин, Рила и Родопи.",
    },
    {
        id: 3,
        name: "Апартамент",
        description: "Предлагаме Ви различни възможности за настаняване в Апартамент:",
        additionalDescription: [
            {
                name: "Спалня + хол, като в хола има и разтегателно легло",
                prices: {
                    summer: 70,
                    winter: 70
                }
            }, {
                name: "2 спални",
                prices: {
                    summer: 80,
                    winter: 80
                }
            }, {
                name: "2 спални + дневна, в която има разтегателно легло + 2 санитарни възела",
                prices: {
                    summer: 100,
                    winter: 100
                }
            }
        ],
        images: apartment,
        type: "apartament",
        prices: {
            summer: "*70-100",
            winter: "*70-100"
        },
        services: apartmentServices,
        details: [
            {
                name: "Легла",
                content: "Спалня за двама"
            }, {
                name: "Закуска",
                content: "Не е включена"
            }, {
                name: "Интернет",
                content: "Включен в пакета, високоскоростен"
            }, {
                name: "Тераса",
                content: "Да"
            }
        ],
        keywords: "апартамент",
        metaDescription: "Апартамент в уют и природен баланс. Насладете се на комфорт, природа и забележителности, заобиколени от планинския триъгълник на Пирин, Рила и Родопи.",
    },
]
