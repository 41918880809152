import React from 'react';
import styled from 'styled-components';
import Line from '../Line';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 500px;
    flex: 1;
    background: #f1f2f2;
    width: 100%;
`;

const Title = styled.h2`
    font-size: 2.5rem;
    width: 80%;
    margin: 15px auto;

    ${props => props.theme.media.tablet} {
        font-size: 3.125rem;
    }
    ${props => props.theme.media.desktop} {
        margin: inherit auto;
    }
`;

const Description = styled.p`
    font-size: 1.375rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: none;
    ${props => props.theme.media.tablet} {
        display: block;
    }
`;

const TitleLine = styled.div`
    display: none;
    ${props => props.theme.media.tablet} {
        display: block;
    }
`;

const DescribedInfo = () => {
    return (
        <Wrapper>
            <Title>Безплатните ни услуги за Вас</Title>
            <TitleLine>
                <Line />
            </TitleLine>
            <Description>
                За всеки от нашите гости предлагаме и безплатни допълнителни услуги, които да направят гостуването Ви максимално комфортно и уютно.
                Можете да се възползвате от тях напълно свободно, за целия престой.
            </Description>
        </Wrapper>
    )
}

export default DescribedInfo;