import React from 'react';
import styled from 'styled-components';
import Service from './Service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWifi, faParking, faHeart, faUtensils} from '@fortawesome/free-solid-svg-icons'

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    width: 100%;

    ${props => props.theme.media.tablet} {
        flex: 1;
    }

    ${props => props.theme.media.desktop} {
        height: 500px;
    }
`;

const ServicesInfo = () => {
    const services = [
        {
            id: 1,
            name: "Wi-Fi",
            icon: <FontAwesomeIcon icon={faWifi} size="2x" />,
            description: "Безплатна WiFi връзка на територията на хотела"
        }, {
            id: 2,
            name: "Безплатен паркинг",
            icon: <FontAwesomeIcon icon={faParking} size="2x" />,
            description: "Безплатна, открита парко зона"
        }, {
            id: 3,
            name: "Relax зона",
            icon: <FontAwesomeIcon icon={faHeart} size="2x" />,
            description: "Relax зона"
        }, {
            id: 4,
            name: "Ресторант",
            icon: <FontAwesomeIcon icon={faUtensils} size="2x" />,
            description: "Relax зона"
        },
    ];
    return (
        <Wrapper>
            {services.map(service => (
                <Service key={service.id} {...service}/>
            ))}
        </Wrapper>
    )
}

export default ServicesInfo;