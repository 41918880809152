import { createGlobalStyle } from 'styled-components';
 
const GlobalStyle = createGlobalStyle`
  html {
    font-family: 'PT Sans', sans-serif;
  }
  html, body {
    margin: 0;
    padding: 0;
  }
  h1 {
    font-size: 1.5em;
  }
`;
 
export default GlobalStyle;