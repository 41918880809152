import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { Room as RoomData } from '../data/rooms';
import Places from './components/Places';
import RoomService from './components/RoomServices/index';
import Services from './components/RoomPage/Services';
import Gallery from "react-photo-gallery";
import Carousel from './components/Header/Carousel';
import { Helmet } from "react-helmet";
import { SEO } from "./components/assets";

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Header = styled.div`
    width: 100%;
    height: 210px;
    background: #ccc;
    overflow: hidden;
    position: relative;
    ${props => props.theme.media.tablet} {
        height: 400px;
    }
`;

const Content = styled.div`
    width: 95%;
    margin: 0 auto;

    ${props => props.theme.media.tablet} {
        width: 80%;
    }
`;

const ContentHeader = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 0;

    ${props => props.theme.media.desktop} {
        padding-top: 35px;
    }

    ${props => props.theme.media.desktop} {
        flex-direction: row;
    }
`;

const Images = styled.div`
    flex: 0 0 70%;
`;

const Image = styled.img.attrs(props => ({
    src: props.src || ""
}))`
    width: 200px;
`;


const Details = styled.div`
    margin-top: 30px;
    ${props => props.theme.media.tablet} {
        margin: 0;
        padding: 0 30px;
    }
`;

const DetailsHeader = styled.div`
    background: #bdc9e0;
    padding: 15px;
    border: 1px solid #bdc9e0;
`;

const DetailsHeaderTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;
const Title = styled.h1`
    margin-top: 0;
    margin-bottom: 0;
`;

const Price = styled.h1`
    margin-top: 0;
    margin-bottom: 0;
`;

const DetailsHeaderDescription = styled.h3`
    font-weight: normal;
`;

const DetailsContent = styled.div`
    border: 1px solid #bdc9e0;
`;

const Detail = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: ${props => props.color ? "#fff" : "#e0eaf4"};
    padding: 15px;
`
const DetailName = styled.div``
const DetailContent = styled.div`
    text-align: right;
`

const RoomServices = styled.div`
    padding: 35px 0;
`;

const ResponsiveTitle = styled.h1`
    font-size: 2em;
    ${props => props.theme.media.tablet} {
        display: none;
    }
`;


const getRoomPrice = (prices: any) => {
    if (prices) {
        const summerMonths = [5, 6, 7, 8, 9, 10];
        const month = new Date().getMonth() + 1;
        const isSummer = summerMonths.includes(month);
        return (<>{isSummer ? prices.summer : prices.winter}лв.</>)
    }
    return null;
}

const openImage = (event: any) => {
    // console.log(event.target.currentSrc);
    // console.log(event.target.outerHTML);
}

const Room = (props: any) => {
    const [roomData, setRoomData] = useState({});

    useEffect(() => {
        let data = RoomData.find(rd => rd.id === Number(props.match.params.roomId));
        setRoomData(data);
    }, [props]);

    const images = roomData.images;
    const details = roomData.details;

    return (
        <>
            <Container>
                <Helmet>
                    <title>{`${roomData.name} | къща за гости "Магделина"`}</title>
                    <meta name="description" content={`${roomData.metaDescription}`} />
                    <meta name="keywords" content={`${roomData.keywords}, почивка в България, почивка на планина, къща за гости магделина, почивка, лято, зима, къща за гости добринище, къща за гости около софия, къща за гости за нова година, къща за гости в планината, магделина, банско, туризъм, курорт, зимни курорти, Пирин, Рила, Родопи, Банско, ски, минерални басейни, добринище, хотели в добринище, почивка в добринище, ски в добринище, лифт добринище`} />

                    <meta property="og:title" content={`${roomData.name} | къща за гости 'Магделина'`} />
                    <meta property="og:description" content={`${roomData.metaDescription}`} />
                    <meta property="og:image" content={SEO.home} />
                    <meta property="og:url" content={`https://magdelina.bg/#/${roomData.type}`} />
                    <meta property="og:site_name" content={`${roomData.name} | къща за гости 'Магделина'`} />
                </Helmet>
                <Header >
                    <Carousel text={false} />
                </Header>
                <Content>
                    <ResponsiveTitle>{roomData.name}</ResponsiveTitle>
                    <ContentHeader>
                        <Images>
                            {/* <picture>
                                <source srcSet={images && images.imagewebp} type="image/webp" />
                                <source srcSet={images && images.imagejpg} type="image/jpeg" />
                                <Image src={images && images.imagejpg} alt="Alt Text!" />
                            </picture> */}
                            {images && <Gallery photos={images.gallery} onClick={() => openImage(event)} />}
                        </Images>
                        <Details>
                            <DetailsHeader>
                                <DetailsHeaderTitle>
                                    <Title>
                                        {roomData.name}
                                    </Title>
                                    <Price>
                                        {getRoomPrice(roomData.prices)}
                                    </Price>
                                </DetailsHeaderTitle>
                                <DetailsHeaderDescription>
                                    {roomData.description}
                                    {roomData.type === "apartament" && roomData.additionalDescription
                                        && roomData.additionalDescription.map((description, i) => (
                                            <li key={i}>{description.name}, цена: <b>{getRoomPrice(description.prices)}</b></li>
                                        ))}
                                </DetailsHeaderDescription>
                            </DetailsHeader>
                            <DetailsContent>
                                {details && details.map((detail, i) => (
                                    <Detail key={i} color={i % 2}>
                                        <DetailName>{detail.name}</DetailName>
                                        <DetailContent>{detail.content}</DetailContent>
                                    </Detail>
                                ))}
                            </DetailsContent>
                        </Details>
                    </ContentHeader>
                    <RoomServices>
                        {/* <RoomService type={roomData.type} /> */}
                        <Services {...roomData} />
                    </RoomServices>
                </Content>
            </Container>
            <Places />
        </>
    )
}

export default Room;