import React, { useRef } from 'react';
import styled from 'styled-components';
import ServicesInfo from './ServicesInfo';
import DescribedInfo from './DescribedInfo';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;

    ${props => props.theme.media.desktop} {
        flex-direction: row;
    }
`;
const Services = () => {
    return (
        <Wrapper>
            <DescribedInfo />
            <ServicesInfo />
        </Wrapper>
    )
}

export default Services;