import React, { useState } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun } from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react';
import axios from 'axios';

import NumberFormat from 'react-number-format';
const Wrapper = styled.div`
    text-align: center;
    background: #e8c15f;
`;

//FFBF00
const Anchor = styled.a.attrs(props => ({
    href: props.href || "#"
}))`
    display: block;
    padding: 10px 7px;
    color: #2b2924;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
`;

const WeatherIcon = styled.img.attrs(props => ({
    src: props.src || ""
}))`
    display: block;
    width: 50px;
`;

const Temperature = styled.span`
    margin-top: -5px;
    font-weight: bold;
`;

const Weather = () => {
    const [weather, setWeather] = useState(0);
    const [icon, setIcon] = useState('');

    useEffect(async () => {
        const fetchData = async () => {
        const response = await fetch('https://api.openweathermap.org/data/2.5/weather?q=Dobrinishte,2777&appid=d846588477b17c4494db44c1edc1fc52&units=metric');
            const data = await response.json();
            const { weather, main } = data;
            setWeather(main.temp);
            setIcon(`http://openweathermap.org/img/wn/${weather[0].icon}@2x.png`)
        }

        fetchData()
    }, []);
    return (
        <Wrapper>
            {/* <Anchor> */}
            <Content>
                <WeatherIcon src={icon} />
                <NumberFormat
                    value={weather}
                    displayType={'text'}
                    suffix={'\xB0C'}
                    decimalScale={0}
                    renderText={(value: any, props: any) => <Temperature {...props}>{value}</Temperature>}
                />
            </Content>
            {/* </Anchor> */}
        </Wrapper>
    )
}

export default Weather;