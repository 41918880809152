import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'

const Wrapper = styled.div`
    text-align: center;
`;

const Anchor = styled.a.attrs(props => ({
    href: props.href || "https://goo.gl/maps/j3Em4XcBeG8ctvSJ8",
    target: "_blank"
}))`
    display: block;
    background: #b8d8be;
    padding: 10px 7px;
    color: #2b2924;
`;

const MapPin = () => {
    return (
        <Wrapper>
            <Anchor>
                <FontAwesomeIcon icon={faMapMarkerAlt} size="lg" />
            </Anchor>
        </Wrapper>
    )
}

export default MapPin;