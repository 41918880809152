import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    position: relative;
    margin-top: 15px;
    flex: 1;
    ${props => props.theme.media.tablet} {
        margin-top: 0;
    }
`;

const Icon = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const Title = styled.h3`
    display: flex;
    align-items: center;
    margin-top: 7px;
`;

const Description = styled.div`

`;

const InfoCard = ({
    name,
    icon,
    description
}) => {
    return (
        <Wrapper>
            <Icon>
                <FontAwesomeIcon icon={icon} size="lg" />
            </Icon>
            <Content>
                <Title>{name}</Title>
                <Description dangerouslySetInnerHTML={{ __html: description }} />
            </Content>
        </Wrapper>
    )
}

export default InfoCard;