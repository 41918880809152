import React, { useRef } from 'react';
import styled from 'styled-components';
import Service from './Service';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
`;
const Services = ({ services }: any) => {
    return (
        <Wrapper>
            {services && services.map((p: any, i: any) => (
                <Service key={i} {...p} />
            ))}
        </Wrapper>
    )
}

export default Services;