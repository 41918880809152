import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex: 0 0 50%;
    padding: 25px 0;
    background: #fff;
    transition: background .1s linear;
    &:hover {
        background: rgba(15,36,83,0.1);
    }

    ${props => props.theme.media.desktop} {
        padding: 0;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    text-align: center;

    ${props => props.theme.media.tablet} {
        width: 60%;
    }
`;

const Icon = styled.div`

`;

const Name = styled.h1`
    margin-bottom: 0;
    font-size: 1.2em;

    ${props => props.theme.media.tablet} {
        font-size: 1.5em;
    }
`;

const Description = styled.p`
    text-align: center;
`;

const Service = ({
    name,
    icon,
    // description
}: any) => {
    return (
        <Wrapper>
            <Content>
                <Icon>{icon}</Icon>
                <Name>{name}</Name>
                {/* <Description>{description}</Description> */}
            </Content>
        </Wrapper>
    )
}

export default Service;