import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 80%;
    height: 3px;
    margin: 0 auto;
`;

const LineWrapper = styled.div`
    width: 250px;
    height: 100%;
    background: ${props => props.color};
    border-radius: 10px;
`;

const Line = ({ color = '#ccc' }) => {
    return (
        <Wrapper>
            <LineWrapper color={color} />
        </Wrapper>
    )
}

export default Line;