const canUseWebp: () => boolean = () => {
    const element = document.createElement('canvas');
    if ((element.getContext && element.getContext('2d'))) {
        return element.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }

    return false;
}

export const useBackgroundWebp: (a: string, b: string) => string = (webPBg: string, imgBg: string) => canUseWebp() ? webPBg : imgBg;
