import React from 'react';
import styled from 'styled-components';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Home from './Home';
import Rooms from './Rooms';
import Room from './Room';
import Navigation from './components/Header/Navigation';
import Footer from './components/Footer';
import UserUtils from './components/UserUtils';
import Contact from './Contact';

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const App = () => {
    return (
        <Router>
            <UserUtils />
            <Navigation />
            <Container>
                <Switch>
                    <Route path="/" exact component={Home} />
                    <Route path="/stai" exact component={Rooms} />
                    <Route path="/kontakti" exact component={Contact} />
                    <Route path="/stai/:roomId/:roomType" exact render={(props) => {
                        return <Room {...props} />;
                    }} />
                </Switch>
            </Container>
            <Footer />
        </Router>
    )
}

export default App;