import React from 'react';
import styled from 'styled-components';
import MapPin from './MapPin';
import Weather from './Weather';
import Phone from './Phone';

const Wrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 0;
    display: flex;
    flex-direction: column;
    z-index: 9999;
    ${props => props.theme.media.tablet} {
        top: 60%;
    }
`;

const UserUtils = () => {
    return (
        <Wrapper>
            <Weather />
            <MapPin />
            <Phone />
        </Wrapper>
    )
}

export default UserUtils;