import React from 'react';
import styled from 'styled-components';
import Line from '../Line';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 500px;
    flex: 1;
    background: #f1f2f2;
    order: 0;
    width: 100%;
    ${props => props.theme.media.desktop} {
        order: 1;
    }
`;

const Title = styled.h2`
    font-size: 2.5rem;
    width: 80%;
    margin: 15px auto;

    ${props => props.theme.media.tablet} {
        font-size: 3.125rem;
    }

    ${props => props.theme.media.desktop} {
        margin: inherit auto;
    }
`;

const Description = styled.p`
    font-size: 1.375rem;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: none;
    ${props => props.theme.media.tablet} {
        display: block;
    }
`;

const TitleLine = styled.div`
    display: none;
    ${props => props.theme.media.tablet} {
        display: block;
    }
`;

const DescribedInfo = () => {
    return (
        <Wrapper>
            <Title>Отдалечености</Title>
            <TitleLine>
                <Line />
            </TitleLine>
            <Description>
                Освен забележителностите в близост, можете да откриете кътчета за развлечения, ресторанти, хранителни магазини и още. През зимния сезон може да се насладите на ски-пистите над Добринище или в Банско, който се намира само на 6км от града. През лятото може да разпускате или да се развличате в минераления плажен комплекс.
            </Description>
        </Wrapper>
    )
}

export default DescribedInfo;