import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Wrapper = styled.div`
    display: flex;
    flex: 0 0 50%;
    align-items: center;
    background: #fff;
    transition: background .1s linear;
    margin: 5px 0;
    min-height: 150px;
    &:hover {
        background: rgba(15,36,83,0.1);
    }
    
    ${props => props.theme.media.tablet} {
        flex: 0 0 calc(20% - 2px);
        border: 1px solid #bdc9e0;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    text-align: center;
`;

const Icon = styled.div`

`;

const Name = styled.h1`
    margin-bottom: 0;
    font-size: 1.2em;
`;

const Service = ({
    name,
    icon
}) => {
    return (
        <Wrapper>
            <Content>
                <Icon>
                    <FontAwesomeIcon icon={icon} size="2x" />
                </Icon>
                <Name>{name}</Name>
            </Content>
        </Wrapper>
    )
}

export default Service;