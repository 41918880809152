import React from 'react';
import Carousel from './Carousel';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
`;
const Header = () => {
    return (
        <Wrapper>
            <Carousel />
        </Wrapper>
    )
}

export default Header;