import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    flex: 0 0 50%;
    background: #fff;
    transition: background .1s linear;
    padding: 25px 0;
    &:hover {
        background: rgba(15,36,83,0.1);
    }

    ${props => props.theme.media.desktop} {
        padding: 0;
    }

    ${props => props.theme.media.ulg} {
        flex: 0 0 25%;
    }
`;
const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
    text-align: center;
`;

const Icon = styled.div`

`;

const Name = styled.h1`
    display: flex;
    align-items: baseline;
    margin-bottom: 0;
    font-size: 1.2em;

    ${props => props.theme.media.tablet} {
        font-size: 1.5em;
    }
`;

const Distance = styled.span`
    font-size: 14px;
`;

const RemotePlace = ({
    name,
    distance,
    icon,
}) => {
    return (
        <Wrapper>
            <Content>
                <Icon>{icon}</Icon>
                <Name>{name}
                    <Distance>~{distance}</Distance>
                </Name>
            </Content>
        </Wrapper>
    )
}

export default RemotePlace;