import React from 'react';
import styled from 'styled-components';
import { HomeVideo } from '../assets';

const Wrapper = styled.div`
    height: 210px;
    ${props => props.theme.media.tablet} {
        height: 400px;
    }

    ${props => props.theme.media.desktop} {
        height: 600px;
    }
`;

const Content = styled.div`
    position: relative;
    height: 100%;
    overflow: hidden;
`;
const Title = styled.h1`
    width: 100%;
    font-size: 1.8rem;
    text-align: center;
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -48%);
    margin: 0;
    color: #fff;
    z-index: 9999;

    ${props => props.theme.media.tablet} {
        font-size: 5em;
    }
`;

const Video = styled.video.attrs({
    autoPlay: true,
    loop: true,
    muted: true,
    playsInline: true,
    preload: 'none',
    controls: false,
    controlList: 'nodownload'
})`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
`;

const Backdrop = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 9998;
    opacity: 0.6;
`;

const Carousel = ({ text = true }) => {
    return (
        <Wrapper>
            <Content>
                <Backdrop />
                <Video>
                    <source type="video/mp4" src={HomeVideo} />
                    Your browser does not support HTML5 video.
                </Video>
                {text &&
                    <Title>
                        <i>Добре дошли,<br />къща за гости "Магделина"</i>
                    </Title>
                }
            </Content>
        </Wrapper>
    )
}

export default Carousel;