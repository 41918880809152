import React from 'react';
import styled from 'styled-components';
import Place from './Place';
import {
    mineralenPlaj,
    bezimenniyatGrad,
    atrakcionenPark,
    ribarnika,
    obidimskiManastir,
    konnaBaza,
    kashtaNikolValpcarov,
    kashtaNeofitRilski
} from '../assets';
const Wrapper = styled.div`
    display: flex;
    overflow-y: scroll;
    ${props => props.theme.media.desktop} {
        overflow: hidden;
        flex-wrap: wrap;
        justify-content: center;
    }
`;

const Places = () => {
    const places = [
        {
            id: 1,
            name: 'Безименният град, Добринище',
            images: bezimenniyatGrad,
            link: "https://drumivdumi.com/%D0%B1%D0%B5%D0%B7%D0%B8%D0%BC%D0%B5%D0%BD%D0%BD%D0%B8%D1%8F%D1%82-%D0%B3%D1%80%D0%B0%D0%B4-%D0%BA%D1%80%D0%B0%D0%B9-%D0%B4%D0%BE%D0%B1%D1%80%D0%B8%D0%BD%D0%B8%D1%89%D0%B5/"
        },
        {
            id: 2,
            name: 'Къща музей на „Неофит Рилски“',
            images: kashtaNeofitRilski,
            link: "https://bg.wikipedia.org/wiki/%D0%9A%D1%8A%D1%89%D0%B0_%D0%BC%D1%83%D0%B7%D0%B5%D0%B9_%E2%80%9E%D0%9D%D0%B5%D0%BE%D1%84%D0%B8%D1%82_%D0%A0%D0%B8%D0%BB%D1%81%D0%BA%D0%B8%E2%80%9C"
        },
        {
            id: 3,
            name: 'Минерален басейн',
            images: mineralenPlaj,
            link: "https://www.mydobrinishte.com/bg/%D0%B4%D0%BE%D0%B1%D1%80%D0%B8%D0%BD%D0%B8%D1%89%D0%B5/310-%D0%BC%D0%B8%D0%BD%D0%B5%D1%80%D0%B0%D0%BB%D0%B5%D0%BD-%D0%BF%D0%BB%D0%B0%D0%B6-%D0%B4%D0%BE%D0%B1%D1%80%D0%B8%D0%BD%D0%B8%D1%89%D0%B5"
        },
        {
            id: 4,
            name: 'Местността „Рибарника“',
            images: ribarnika,
            link: "https://ribarnika.bg/"
        },
        {
            id: 5,
            name: 'Обидимски манастир „Св. Пантелеймон“',
            images: obidimskiManastir,
            link: "https://visit-dobrinishte.bg/bg/%D0%BA%D0%B0%D0%BA%D0%B2%D0%BE-%D0%B4%D0%B0-%D0%B2%D0%B8%D0%B4%D1%8F/item/25-%D0%BC%D0%B0%D0%BD%D0%B0%D1%81%D1%82%D0%B8%D1%80-%D1%81%D0%B2%D0%B5%D1%82%D0%B8-%D0%BF%D0%B0%D0%BD%D1%82%D0%B5%D0%BB%D0%B5%D0%B9%D0%BC%D0%BE%D0%BD"
        },
        {
            id: 6,
            name: 'Конна база "Периволас", село Бачево',
            images: konnaBaza,
            link: "http://www.perivolasbg.com/"
        },
        {
            id: 7,
            name: 'Къща музей на „Никола Вапцаров“',
            images: kashtaNikolValpcarov,
            link: "https://bg.wikipedia.org/wiki/%D0%9A%D1%8A%D1%89%D0%B0_%D0%BC%D1%83%D0%B7%D0%B5%D0%B9_%E2%80%9E%D0%9D%D0%B8%D0%BA%D0%BE%D0%BB%D0%B0_%D0%92%D0%B0%D0%BF%D1%86%D0%B0%D1%80%D0%BE%D0%B2%E2%80%9C_(%D0%91%D0%B0%D0%BD%D1%81%D0%BA%D0%BE)"
        },
        {
            id: 8,
            name: 'Атракционен парк "Не се отказвай"',
            images: atrakcionenPark,
            link: "https://visit-dobrinishte.bg/bg/%D0%B7%D0%B8%D0%BC%D0%B0/item/4318-%D0%B0%D1%82%D1%80%D0%B0%D0%BA%D1%86%D0%B8%D0%BE%D0%BD%D0%B5%D0%BD-%D0%BF%D0%B0%D1%80%D0%BA-%D0%BD%D0%B5-%D1%81%D0%B5-%D0%BE%D1%82%D0%BA%D0%B0%D0%B7%D0%B2%D0%B0%D0%B9"
        }
    ]
    return (
        <Wrapper>
            {places.map(place => (
                <Place key={place.id} {...place} />
            ))}
        </Wrapper>
    )
}

export default Places;